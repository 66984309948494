import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  AlertColor,
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@mui/material'
import FeatureImageDrawer from '../images/applogie-logo-black.svg'
import { LogOutResponseBody } from 'reduxStore/logOut/Model'
import AlertDialog from 'components/AlertDialog'
import { logOut, updateLogOutClearRes } from 'reduxStore/logOut/logOutSlice'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { DrawerList } from './AppFrame/DrawerLisr'
import { ApplogieAppBar } from './AppFrame/ApplogieAppBar'
import ApplogieSnackbar from './ApplogieSnackbar'
interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  windows?: () => Window
}

const drawerWidth = 240

export default function AppFrame(props: Props) {
  const dispatch = useAppDispatch()
  const token = localStorage.getItem('token')
  const { windows } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [showTabIndicator, setShowTabIndicator] = React.useState(true)

  const [pageName, setPageName] = React.useState('')

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const [showAlertDialog, setShowAlertDialog] = React.useState(false)
  const updateAlertDialogState = () => {
    setShowAlertDialog(true)
  }

  const updateAlertDialogValFromChild = (passedVal: boolean) => {
    setShowAlertDialog(passedVal)
  }

  const logoutBody = {
    token: token,
  } as LogOutResponseBody

  async function handleLogout() {
    await dispatch(logOut(logoutBody))
    await setPageName('')
  }
  // ==== List Item====
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const handleListItemClick = (
    _event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index)
  }

  const location = useLocation()
  React.useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    {
      if (location.pathname === '/dashboard' || location.pathname === '/') {
        setValue(0)
        setSelectedIndex(0)
        setPageName('Overview')
        setShowTabIndicator(true)
      } else if (location.pathname === '/subscriptions' || location.pathname.includes('/detail')) {
        setValue(1)
        setSelectedIndex(1)
        setPageName('Subscriptions')
        setShowTabIndicator(true)
      } else if (location.pathname === '/users' || location.pathname.includes('/userdetail')) {
        setValue(2)
        setSelectedIndex(2)
        setPageName('Users')
        setShowTabIndicator(true)
      } else if (location.pathname === '/optimizations') {
        setValue(3)
        setSelectedIndex(3)
        setPageName('optimizations')
        setShowTabIndicator(true)
      } 
      // else if (location.pathname === '/breaches' || location.pathname.includes('/breachdetail')) {
      //   setValue(4)
      //   setSelectedIndex(4)
      //   setPageName('Breaches')
      //   setShowTabIndicator(true)
      // } 
      else {
        setValue(0)
        setSelectedIndex(0)
        setPageName('')
        setShowTabIndicator(false)
      }
    }
  }, [location])

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <img src={FeatureImageDrawer} width="160" height="40" alt="" />
      <Divider />
      <List>
        {token ? (
          <div>
            <List component="nav" aria-label="main mailbox folders">
              {DrawerList.slice(0, 5).map((item, index) => {
                return (
                  <ListItem component={Link} to={item.path} key={item.name} disablePadding>
                    <ListItemButton
                      selected={selectedIndex === index}
                      onClick={(event) => handleListItemClick(event, 0)}
                    >
                      <ListItemText primary={item.name} />
                    </ListItemButton>
                  </ListItem>
                )
              })}
              <ListItemButton selected={selectedIndex === 5} onClick={() => handleLogout()}>
                <ListItemText>
                  <Typography variant="body1" fontSize={14} style={{ color: '#006fbb' }}>
                    Log Out
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </List>
          </div>
        ) : (
          <List component="nav" aria-label="main mailbox folders">
            {DrawerList.slice(5).map((item, index) => {
              return (
                <ListItem component={Link} to={item.path} key={item.name} disablePadding>
                  <ListItemButton
                    selected={selectedIndex === index}
                    onClick={(event) => handleListItemClick(event, 0)}
                  >
                    <ListItemText primary={item.name} />
                  </ListItemButton>
                </ListItem>
              )
            })}
          </List>
        )}
      </List>
    </Box>
  )

  const container = windows !== undefined ? () => windows().document.body : undefined

  // ====== Tabs ======

  const [value, setValue] = React.useState(0)
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const tabLayout = (
    <Box sx={{ width: '100%' }}>
      <Tabs
        onChange={handleChange}
        value={value}
        aria-label="Tabs where selection follows focus"
        selectionFollowsFocus
        textColor="secondary"
        TabIndicatorProps={{
          style: {
            backgroundColor: '#01cba3',
            display: showTabIndicator ? 'block' : 'none',
          },
        }}
      >
        {DrawerList.slice(0, 4).map((item, index) => {
          return (
            <Tab
              label={item.name}
              component={Link}
              to={item.path}
              key={index}
              style={{ textDecoration: 'none', color: '#ffffff' }}
            />
          )
        })}
      </Tabs>
    </Box>
  )

  const layout = (
    <Box sx={{ display: 'flex' }}>
      <ApplogieAppBar
        pageName={pageName}
        tabLayout={tabLayout}
        updateDrawerState={handleDrawerToggle}
        updateAlertDialogState={updateAlertDialogState}
        appLogout={handleLogout}
      />
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main">
        <Toolbar />
      </Box>
    </Box>
  )
  const logOutRes = useAppSelector((state) => state.logOut)
  const [resetPwd, setRestPwd] = React.useState(false)
  const [logOutMsgType, setLogOutMsgType] = React.useState<AlertColor>('success')
  const [logOutMsg, setLogOutMsg] = React.useState('')

  React.useEffect(() => {
    if (logOutRes?.successMsg !== '') {
      setLogOutMsgType('success')
      setLogOutMsg(logOutRes?.successMsg)
      setRestPwd(logOutRes?.hasResetPassword)
    }
    if (logOutRes?.errorMsg !== '') {
      setLogOutMsgType('error')
      setLogOutMsg(logOutRes?.errorMsg)
      setRestPwd(logOutRes?.hasResetPassword)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logOutRes])

  const closeAddUserSnackbar = (passedVal: boolean) => {
    if (passedVal && resetPwd) {
      window.location.assign("/login?org=resetpwd");
      setLogOutMsg('')
      dispatch(updateLogOutClearRes())
    }
    else{
      window.location.assign("/login");
    }
  }
  return (
    <Box>
      {layout}
      {showAlertDialog ? (
        <AlertDialog
          alertDialogEnable={showAlertDialog}
          alertDialogUpdateState={updateAlertDialogValFromChild}
        />
      ) : null}
      {logOutMsg !== '' && (
        <ApplogieSnackbar msg={logOutMsg} type={logOutMsgType} alertClose={closeAddUserSnackbar} />
      )}
    </Box>
  )
}
