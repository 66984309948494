import * as React from 'react'
import { useAppSelector } from '../../../reduxStore/hooks'
import { Card, CardContent, Stack, Typography, Chip, CardHeader, Box, Grid } from '@mui/material'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import { numberWithCommas } from 'utils/numberWithCommas'
import OptimizationTable from 'pages/Optimizations/OptimizationTable'
import OptimizationCardHeader from 'pages/Optimizations/OptimizationCardHeader'
// import BreachesHeader from 'components/Breaches/BreachesHeader'
// import BreachesTable from 'pages/Breaches/BreachesTable'
interface Props {
  reloadOptimization: () => void
  // reloadBreach: () => void
}
export const Summary = ({ reloadOptimization, }: Props) => {
  const subsVal = useAppSelector((state) => state.subscription)
  const usagesVal = useAppSelector((state) => state.currentUsage)

  return (
    <Card sx={{ minHeight: '587px' }}>
      <CardHeader
        avatar={<TextSnippetIcon sx={{ fontWeight: 'bold' }} />}
        title={
          <Typography variant="h6">
            <b>Summary</b>
          </Typography>
        }
      />
      <CardContent>
        <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
          <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
            <Typography variant="h6">
              <b>
                Subscriptions&nbsp;&nbsp;
                <Chip
                  label={
                    'results' in subsVal.subscriptions ? subsVal.subscriptions.results.length : null
                  }
                />
              </b>
            </Typography>

            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Grid item>
                <Typography variant="subtitle2">Spend (last 12 months)</Typography>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#134873' }}>
                  {usagesVal?.spendDetails?.total! !== undefined
                    ? numberWithCommas(Math.round(usagesVal.spendDetails.total))
                    : 'Not Set'}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">Monthly Average Spend</Typography>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#134873' }}>
                  {usagesVal?.spendDetails?.average! !== undefined
                    ? numberWithCommas(Math.round(usagesVal.spendDetails.average))
                    : 'Not Set'}
                </Typography>
              </Grid>
            </Grid>
            <Box>
              <OptimizationCardHeader reloadOptimization={reloadOptimization} />
              <Box style={{ height: '435px' }}>
                <OptimizationTable />
              </Box>
            </Box>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}
