import { configureStore } from '@reduxjs/toolkit'
import alertReducer from './alert/Active/alertSlice'
import forgotPasswordSlice from './forgotPassword/forgotPasswordSlice'
import loginReducer from './login/loginSlice'
import logOutSlice from './logOut/logOutSlice'
import monthlyvendonspendReducer from './vendorSpendLicesnseRenewal/monthlyVendorSpendSlice'
import monthlyVendorSpendUsageReducer from './vendorSpendUsage/monthlyVendorSpendUsageSlice'
import subscriptionsSlice from './subcription/Active/subscriptionSlice'
import fetchBreachesSlice from './breaches/breachesList/breachSlice'
import fetchOptimizationSlice from './optimizations/optimizationsList/optimizationSlice'
import fetchVendorSpendSlice from './vendorCategory/vendorCategorySlice'
import fetchVendorDeptSlice from './vendorDept/vendorDeptSlice'
import hideAlertSlice from './alert/Hide/hideAlertSlice'
import ignoredsubscriptionSlice from './subcription/Ignored/ignoredsubscriptionSlice'
import vendorDeptDetailsSlice from './vendorDeptDetails/vendorDeptDetailsSlice'
import fetchVendorDeptCategoryDetails from './vendorCategory/VendorDeptCatDetails/vendorDeptCategoryDetailsSlice'
import fetchVendorSpendDetails from './vendorSpendLicesnseRenewal/vendorSpendDetails/vendorSpendDetailsSlice'
import activeuserSlice from './users/Active/activeuserSlice'
import inactiveuserSlice from './users/Inactive/inactiveuserSlice'
import accountDetailsSlice from 'reduxStore/account/accountDetailsById/accountDetailsSlice'
import companyAccountDetailsSlice from 'reduxStore/account/companyAccountDetailsById/accountDetailsSlice'
import statusSlice from './subcription/Status/StatusSlice'
import departmentSlice from './subcription/Department/DepartmentSlice'
import accountPaymentListByIdSlice from 'reduxStore/account/accountPaymentList/accountPaymentListByIdSlice'
import accountCustomerBudgetByIdSlice from 'reduxStore/account/accountCustomerBudgetById/accountCustomerBudgetByIdSlice'
import accountCustomerSpendByIdSlice from 'reduxStore/account/accountCustomerSpend/accountCustomerSpendByIdSlice'
import customerDetailsSlice from 'reduxStore/customer/customer/customerSlice'
import updatePasswordSlice from 'reduxStore/updatePassword/updatePasswordSlice'
import updateCustomerDetailsIdSlice from 'reduxStore/updateCustomerDetailsById/updateCustomerDetailsByIdSlice'
import updateSubscriptionSlice from './subcription/updateSubscription/updateSubscriptionSlice'
import licenseSlice from './account/license/licenseListByAccountID/licenseSlice'
import customerPaymentListSlice from 'reduxStore/customer/payment/customerPaymentList/customerPaymentListSlice'
import companyUserListSlice from 'reduxStore/customer/companyUser/companyUserList/companyUserListSlice'
import fetchAccountUserDetailsById from 'reduxStore/account/accountUserDetailsById/accountUserDetailsByIdSlice'
import addLicenseSlice from 'reduxStore/account/license/addLicenseByAccountID/addLicenseSlice'
import paymentVendorListSlice from 'reduxStore/subcription/PaymentVendorList/paymentVendorListSlice'
import tagListByIdSlice from './tagList/tagListByIdSlice'
import updateBudgetDetailsSlice from './updateBudgetDetails/updateBudgetDetailsSlice'
import updateSpendDetailsSlice from './updateSpendDetails/updateSpendDetailsSlice'
import amexSourceSlice from './subcription/Amex/amexSlice'
import inactiveCustomerPaymentByIDSlice from 'reduxStore/customer/payment/inactivePayment/inactiveCustomerPaymentByIDSlice'
import deleteCustomerPaymentByIDSlice from 'reduxStore/customer/payment/deletePayment/deleteCustomerPaymentByIDSlice'
import commentsSlice from 'reduxStore/comments/commentsSlice'
import addCommentSlice from 'reduxStore/comments/addComment/addCommentSlice'
import hideCommentSlice from 'reduxStore/comments/Hide/hideCommentSlice'
import addCompanyUserSlice from 'reduxStore/customer/companyUser/addCompanyUser/addCompanyUserSlice'
import toggleCompnayUserByIDSlice from 'reduxStore/customer/companyUser/toggleCompanyUserByID/toggleCompanyUserByIDSlice'
import addTagSlice from 'reduxStore/addTag/tagListByIdSlice'
import addAccountAgreementDetailsSlice from 'reduxStore/AddAccountAgrementDetails/addAccountAgreementDetailsSlice'
import accountAgreementDetailsByIdSlice from 'reduxStore/account/accountAgreement/getAccountAgreementDetails/accountCustomerBudgetByIdSlice'
import testSourceSlice from './subcription/TestSource/testSourceSlice'
import documentListSlice from './subcription/Document/GetDocument/documentListSlice'
import fetchCommentsListSlice from 'reduxStore/comments/commentList/fetchCommentsListSlice'
import editSubscriptionSlice from './subcription/editSubscription/editSubscriptionSlice'
import hideDocumentSlice from 'reduxStore/subcription/Document/hideDocument/hideDocumentSlice'
import amazonDataByAccountIdSlice from 'reduxStore/account/amazon/amazonDataByAccountIdSlice'
import gcpListSlice from './account/google/gcpListSlice'
import updateLicenseByAccountIDSlice from 'reduxStore/account/license/updateLicenseByAccountID/updateLicensebyAccountID'
import deleteLicenseByIDSlice from './account/license/deleteLicensebyID/deleteLicenseByIDSlice'
import microsoftUserDataByAccountIdSlice from 'reduxStore/account/microsoft/MicrosoftUserListSlice'
import licenseChartSlice from './account/license/licenseChart/licenseChartSlice'
import inactiveUserSlice from 'reduxStore/account/inactiveUser/inactiveUserSlice'
import benchmarkChartSlice from 'reduxStore/account/benchMark/benchMarkChartSlice'
import suspiciousActivitySlice from 'reduxStore/account/suspiciousActivityList/SuspiciousActivityListSlice'
import testTagListSlice from 'reduxStore/subcription/TagList/tagSlice'
import searchTagListSlice from 'reduxStore/subcription/searchTagList/searchTagSlice'
import userDetailsByIdSlice from 'reduxStore/users/userDetails/userDetailsSlice'
import breachesByUserID from 'reduxStore/users/breaches/breachesByUserID'
import microsoftUsageSlice from 'reduxStore/users/microsoftUsage/microsoftUsageSlice'
import searchUserSlice from 'reduxStore/users/searchUser/searchUserSlice'
import updateUserDetailsSlice from 'reduxStore/users/updateUserDetails/updateUserDetailsSlice'
import microsofIntegrationSlice from 'reduxStore/account/microsoftIntegration/microsofIntegrationSlice'
import getTagByUserIdSlice from 'reduxStore/users/tags/tagList/getTagListByIdSlice'
import addTagListSlice from 'reduxStore/users/tags/addTagListById/addTagListByUserIdSlice'
import getToggleActivateByUserIdSlice from 'reduxStore/users/toggleActive/toggleActiveSlice'
import getUserTagListSlice from 'reduxStore/users/userTagList/getUserTagListSlice'
import userListByOrgTagSlice from 'reduxStore/users/userListByOrgTag/userListByOrgTagSlice'
import fetchBreachesDetailsByIDSlice from 'reduxStore/breaches/breachesDetailsByID/breachSlice'
import optimizationsDetailsByAccIDSlice from 'reduxStore/optimizations/optimizationsDetailsbyAccId/optimizationSlice'
import usageSlice from './currentusage/usageSlice'
import userDeviceSlice from 'reduxStore/userdeviceFcmToken/userdeviceSlice'
import generateDemoPreferenceSliceSlice from 'reduxStore/customer/DemoPreference/GenerateDemoPreference/generateDemoPreferenceSlice'
import updatePaymentSlice from 'reduxStore/account/accountUpdatePayment/accountUpdatePaymentSlice'
import deletePaymentByIDSlice from './account/accountPaymentDeleteByID/deletePaymentByIDSlice'
import editCompanyUserSlice from './customer/companyUser/editCompanyUser/editCompanyUserSlice'
import getUsageListByLicenseIDSlice from './account/license/usage/getUsageListByLicenseID/getUsageListByLicenseID'
import updateUsageByIdSlice from './account/license/usage/updateUsageByID/updateUsageById'
import selectSubscriptionForSetDeptSlice from './selectSubscriptionForSetDept/selectSubscriptionForSetDeptSlice'
import vendorLicenseRenewalsSlice from './renewals/vendorLicenseRenewalsSlice'
import clearAllAlertsSlice from './alert/ClearAllAlerts/clearAllAlertsSlice'
import fetchVendorDeptUserListSlice from './vendorDeptUser/vendorDeptUserListSlice'
import MicrosoftUserLicenseSlice from './account/microsoft/MicrosoftUserLicenseSlice'
import DetectWrongPaymentSlice from './account/detectWrongPayment/detectWrongPaymentSlice'
import fetchUserDisplaySettingSlice from './subcription/FetchUserDisplaySetting/fetchUserDisplaySettingSlice'
import createOrUpdateUserDisplaySettingSlice from './subcription/CreateOrUpdateUserDisplaySetting/createOrUpdateUserDisplaySettingSlice'
const store = configureStore({
  reducer: {
    alert: alertReducer,
    login: loginReducer,
    logOut: logOutSlice,
    forgotPassword: forgotPasswordSlice,
    monthlyVendorSpend: monthlyvendonspendReducer,
    monthlyVendorSpendUsage: monthlyVendorSpendUsageReducer,
    subscription: subscriptionsSlice,
    ignoredsubscription: ignoredsubscriptionSlice,
    breaches: fetchBreachesSlice,
    optimization: fetchOptimizationSlice,
    vendorSpend: fetchVendorSpendSlice,
    vendorDept: fetchVendorDeptSlice,
    hideAlert: hideAlertSlice,
    vendorDeptDetails: vendorDeptDetailsSlice,
    vendorDeptCategoryDetails: fetchVendorDeptCategoryDetails,
    vendorSpendDetails: fetchVendorSpendDetails,
    activeUsers: activeuserSlice,
    inactiveUsers: inactiveuserSlice,
    accountDetails: accountDetailsSlice,
    companyAccountDetailsSlice: companyAccountDetailsSlice,
    subscriptionstatus: statusSlice,
    subsdepartment: departmentSlice,
    accountPaymentListById: accountPaymentListByIdSlice,
    customerBudgetByAccountId: accountCustomerBudgetByIdSlice,
    customerSpendByAccountId: accountCustomerSpendByIdSlice,
    customerDetails: customerDetailsSlice,
    updatePassword: updatePasswordSlice,
    updateCustomerDetailsById: updateCustomerDetailsIdSlice,
    updateSubscription: updateSubscriptionSlice,
    license: licenseSlice,
    customerPaymentList: customerPaymentListSlice,
    companyUserList: companyUserListSlice,
    addLicense: addLicenseSlice,
    accountUserDetailsById: fetchAccountUserDetailsById,
    paymentVendorList: paymentVendorListSlice,
    tagListById: tagListByIdSlice,
    updateBudgetDetailsByAccountId: updateBudgetDetailsSlice,
    updateSpendDetailsByAccountId: updateSpendDetailsSlice,
    fetchAmexSource: amexSourceSlice,
    inActiveCustomerpaymentByID: inactiveCustomerPaymentByIDSlice,
    deleteCustomerpaymentByID: deleteCustomerPaymentByIDSlice,
    comments: commentsSlice,
    addComment: addCommentSlice,
    hideComment: hideCommentSlice,
    addCompanyUser: addCompanyUserSlice,
    toggleCompanyUserByID: toggleCompnayUserByIDSlice,
    addTagRes: addTagSlice,
    addAccountAgreementDetailsRes: addAccountAgreementDetailsSlice,
    getAccountAgreementDetailsById: accountAgreementDetailsByIdSlice,
    testSource: testSourceSlice,
    documentList: documentListSlice,
    commentsListRes: fetchCommentsListSlice,
    editSubscriptionfetch: editSubscriptionSlice,
    hideDocument: hideDocumentSlice,
    amazonDataRes: amazonDataByAccountIdSlice,
    gcpList: gcpListSlice,
    updateLicenseByAccountID: updateLicenseByAccountIDSlice,
    deleteLicenseByID: deleteLicenseByIDSlice,
    deletePaymentByID: deletePaymentByIDSlice,
    microsoftUserList: microsoftUserDataByAccountIdSlice,
    licenseChart: licenseChartSlice,
    userInactive: inactiveUserSlice,
    benchMarkChart: benchmarkChartSlice,
    suspiciousActivityList: suspiciousActivitySlice,
    tagList: testTagListSlice,
    searchTagList: searchTagListSlice,
    userDetailsById: userDetailsByIdSlice,
    brachesByUserID: breachesByUserID,
    microsoftUsageById: microsoftUsageSlice,
    userSearch: searchUserSlice,
    updateUserDetails: updateUserDetailsSlice,
    msClientId: microsofIntegrationSlice,
    tagListByUserId: getTagByUserIdSlice,
    addTagsbyUserID: addTagListSlice,
    getToggleActivateByUserId: getToggleActivateByUserIdSlice,
    getUserTagList: getUserTagListSlice,
    userListByOrgTag: userListByOrgTagSlice,
    breachesDetailsByID: fetchBreachesDetailsByIDSlice,
    optimizationsDetailsbyAccountID: optimizationsDetailsByAccIDSlice,
    currentUsage: usageSlice,
    userDevice: userDeviceSlice,
    generateDemoPreferenceRes: generateDemoPreferenceSliceSlice,
    updatePaymentRes: updatePaymentSlice,
    editCompanyUserRes: editCompanyUserSlice,
    getUsageListByLicenseIDRes: getUsageListByLicenseIDSlice,
    updateUsageByIdRes: updateUsageByIdSlice,
    selectSubscriptionForSetDeptRes: selectSubscriptionForSetDeptSlice,
    vendorLicenseRenewalsSliceRes: vendorLicenseRenewalsSlice,
    clearAllAlerts: clearAllAlertsSlice,
    fetchVendorDeptUserListRes: fetchVendorDeptUserListSlice,
    microsoftUserLicense: MicrosoftUserLicenseSlice,
    DetectWrongPayment: DetectWrongPaymentSlice,
    getUserDisplaySettings: fetchUserDisplaySettingSlice,
    createOrUpdateUserDisplaySettings: createOrUpdateUserDisplaySettingSlice
  },
})

export default store
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, alerts: AlertsState}
export type AppDispatch = typeof store.dispatch
