import * as React from 'react'
import { Card, CardContent, Grid, Box, Fab, Stack } from '@mui/material'
import { AlertView } from '../../components/AlertView'
import { Summary } from './Summary/Summary'
import Chart from './VendorSpend/VendorSpendCatRenewal'
import Brightness7Icon from '@mui/icons-material/SettingsOutlined'
import VendorDept from './VendorSpendByDeptChart/VendorDept'
import { useAppDispatch } from 'reduxStore/hooks'
import { fetchAlerts } from 'reduxStore/alert/Active/alertSlice'
import { fetchSubscription } from 'reduxStore/subcription/Active/subscriptionSlice'
import { fetchBreaches } from 'reduxStore/breaches/breachesList/breachSlice'
import { fetchOptimization } from 'reduxStore/optimizations/optimizationsList/optimizationSlice'
import { fetchMonthlyUsageSpends } from 'reduxStore/vendorSpendUsage/monthlyVendorSpendUsageSlice'
import { fetchMonthlyBudgetForecasts } from 'reduxStore/vendorSpendLicesnseRenewal/monthlyVendorSpendSlice'
import { fetchVendorDepts } from 'reduxStore/vendorDept/vendorDeptSlice'
import { fetchVendorSpends } from 'reduxStore/vendorCategory/vendorCategorySlice'
import DashboardDialog from './DashboardDialog'
import { fetchUsage } from 'reduxStore/currentusage/usageSlice'
import { isSectionNameAvailable } from 'components/Utility'
import ApplogieSnackbar from 'components/ApplogieSnackbar'
import PageTitle from 'components/PageTitle'
import { optimizationDetailsByAccIdClearRes } from 'reduxStore/optimizations/optimizationsDetailsbyAccId/optimizationSlice'
import { fetchVendorLicenseRenewals } from 'reduxStore/renewals/vendorLicenseRenewalsSlice'
import { CommentsListPayload } from 'reduxStore/comments/commentList/Model'
import { fetchCommentsList } from 'reduxStore/comments/commentList/fetchCommentsListSlice'
import { fetchVendorDeptUserList } from 'reduxStore/vendorDeptUser/vendorDeptUserListSlice'

export default function Dashboard() {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()

  if (localStorage.getItem('sectionName') === null) {
    localStorage.setItem('sectionName', 'Alerts,Summary,Charts')
  }
  const [successMessage, setSuccessMessage] = React.useState('')
  const [showDialog, setShowDialog] = React.useState(false)
  const updateDialogState = () => {
    setShowDialog(true)
  }

  const updateDialogValFromChild = (passedval: boolean) => {
    setShowDialog(passedval)
  }
  const handleSuccessMessage = (message: string) => {
    // Handle success message in the parent component
    setSuccessMessage(message)
  }

  const closeUserDetailsSnackbar = () => {
    setSuccessMessage('')
  }

  React.useEffect(() => {
    dispatch(fetchAlerts(Token!))
    dispatch(fetchSubscription(Token!))
    dispatch(fetchBreaches(Token!))
    dispatch(fetchOptimization(Token!))
    dispatch(fetchMonthlyUsageSpends(Token!))
    dispatch(fetchSubscription(Token!))

    dispatch(fetchMonthlyBudgetForecasts(Token!))
    dispatch(fetchVendorDepts(Token!))
    dispatch(fetchVendorSpends(Token!))
    dispatch(fetchUsage(Token!))
    dispatch(optimizationDetailsByAccIdClearRes())
    dispatch(fetchVendorLicenseRenewals({ token: Token! }))
    dispatch(
      fetchCommentsList({
        count: '10',
        token: Token!,
      } as CommentsListPayload),
    )
    dispatch(fetchVendorDeptUserList(Token!))
  })

  function reloadOptimization() {
    dispatch(fetchOptimization(Token!))
  }

  return (
    <Box p={2}>
      <PageTitle title={'Dashboard'} />
      {successMessage && (
        <ApplogieSnackbar
          msg={successMessage}
          type="success"
          alertClose={closeUserDetailsSnackbar}
        />
      )}
      {showDialog ? (
        <DashboardDialog dialogEnable={showDialog} updatedDialogState={updateDialogValFromChild} />
      ) : null}
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
            {isSectionNameAvailable('Alerts') && (
              <Card sx={{ minHeight: '440px', maxHeight: '440px' }}>
                <AlertView alertSuccessMessage={handleSuccessMessage} />
              </Card>
            )}
            {isSectionNameAvailable('Summary') && (
              <Summary reloadOptimization={reloadOptimization} 
              />
            )}
          </Stack>
        </Grid>
        {isSectionNameAvailable('Charts') && (
          <Grid item xs={12} sm={12} md={6} lg={8} xl={8}>
            <Card>
              <CardContent>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-evenly"
                  alignItems="stretch"
                  spacing={2}
                >
                  <Grid item>
                    <Chart />
                  </Grid>
                  <Grid item>
                    <VendorDept />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
      <Fab
        sx={{
          background: '#30A289',
          display: 'flex',
          margin: '8px',
          position: 'fixed',
          right: '30px',
          bottom: '90px',
          '&:hover': { cursor: 'pointer', background: '#30A289' },
        }}
        aria-label="add"
        onClick={updateDialogState}
      >
        <Brightness7Icon />
      </Fab>
    </Box>
  )
}
