import * as React from 'react'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  // Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  // InputAdornment,
  // TextField,
  Typography,
  // useMediaQuery,
  // useTheme,
} from '@mui/material'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import RefreshIcon from '@mui/icons-material/Refresh'
import { fetchStatus } from 'reduxStore/subcription/Status/StatusSlice'
import { fetchDepartment } from 'reduxStore/subcription/Department/DepartmentSlice'
import { fetchPaymentVendorList } from 'reduxStore/subcription/PaymentVendorList/paymentVendorListSlice'
import { fetchCompanyUserList } from 'reduxStore/customer/companyUser/companyUserList/companyUserListSlice'
import { SubscriptionList } from './Model'
import { TagSourceReq } from 'reduxStore/subcription/TagList/Model'
import { fetchTaglist } from 'reduxStore/subcription/TagList/tagSlice'
// import { fetchTagSearchlist } from 'reduxStore/subcription/searchTagList/searchTagSlice'
// import { SearchTagSourceReq } from 'reduxStore/subcription/searchTagList/Model'
import { ignoredSubscription } from 'reduxStore/subcription/Ignored/ignoredsubscriptionSlice'
// import { Tags } from '../Active/Model'
// import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridRowParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid-premium'
import { SubscriptionTableColumn } from '../../../components/Subscripiton/SubscriptionTableColumn'
import EditSubscription from '../../../components/Subscripiton/EditSubscription'
import { AllActiveSubscriptionList } from '../../../components/Subscripiton/AllActiveSubscriptionList'
import { ApplogieDGToolbar } from 'components/Subscripiton/DataGridTableHeader'
import { updateSubscriptionList } from 'reduxStore/selectSubscriptionForSetDept/selectSubscriptionForSetDeptSlice'
import { editSubscriptionClearRes } from 'reduxStore/subcription/editSubscription/editSubscriptionSlice'
import { optimizationDetailsByAccIdClearRes } from 'reduxStore/optimizations/optimizationsDetailsbyAccId/optimizationSlice'
import AlertPopup from 'components/Subscripiton/SubscriptionAlert'

export default function IgnoredSubscription() {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  // const theme = useTheme()
  // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const ignoredSubsValue = useAppSelector((state) => state.ignoredsubscription)
  const editSubscriptionValue = useAppSelector((state) => state.editSubscriptionfetch)
  // const tagListValue = useAppSelector((state) => state.tagList)
  const searchTagListValue = useAppSelector((state) => state.searchTagList)
  const individualSubscriptionAlertRes = useAppSelector(
    (state) => state.optimizationsDetailsbyAccountID,
  )

  // Individual subscriptions Alert
  const [showAlertDialog, setShowAlertDialog] = React.useState(false)
  const updateAlertDialogValFromChild = (passedVal: boolean) => {
    setShowAlertDialog(passedVal)
  }

  useEffect(() => {
    setShowAlertDialog(
      individualSubscriptionAlertRes.optimizations?.results !== undefined ? true : false,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [individualSubscriptionAlertRes])

  // const [searchKey, setSearchKey] = React.useState([] as any[])

  let navigate = useNavigate()

  const [subscriptionList, setSubscriptionList] = React.useState([] as SubscriptionList[])

  // Modify dataset for subscription table
  let allSubscriptionList = AllActiveSubscriptionList()
  useEffect(() => {
    allSubscriptionList.length > 0 
      ? setSubscriptionList(allSubscriptionList)
      : setSubscriptionList([] as any[])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSubscriptionList])

  useEffect(() => {
      if(searchTagListValue?.searchTagSource?.results?.length === 0){
        setSubscriptionList([] as any[]) 
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTagListValue]) 

  // const [selectedTag, setSelectedTag] = React.useState<Tags | null>(null)
  // const filterSearch = (owner: any, _e: any) => {
  //   setSelectedTag(owner)
  //   if (owner === null) {
  //     searchParams.delete('searchbytag')
  //     setSearchParams(searchParams)
  //   }
  // }
  // React.useEffect(() => {
  //   if (selectedTag !== null) {
  //     searchParams.set('searchbytag', selectedTag?.name)
  //     setSearchParams(searchParams)
  //     let body = {
  //       name: selectedTag.name,
  //       token: Token!,
  //       status: "True"
  //     } as SearchTagSourceReq
  //     dispatch(fetchTagSearchlist(body))
  //   } else {
  //     dispatch(ignoredSubscription(Token!))
  //   }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedTag])

  let [searchParams, setSearchParams] = useSearchParams()

  // Initiate API call on page render
  useEffect(() => {
    dispatch(ignoredSubscription(Token!))
    dispatch(fetchStatus(Token!))
    dispatch(fetchDepartment(Token!))

    let reqBody = {
      resultCount: '5',
      token: Token!,
    }
    dispatch(fetchPaymentVendorList(reqBody))
    dispatch(fetchCompanyUserList(Token!))
    dispatch(fetchCompanyUserList(Token!))
    let tagBody = {
      token: Token!,
    } as TagSourceReq
    dispatch(fetchTaglist(tagBody))
    dispatch(optimizationDetailsByAccIdClearRes())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, Token])

  // useEffect(() => {
  //   if (tagListValue !== undefined) {
  //     let tagName = ''
  //     let searchKey: any[] = []
  //     if (tagListValue.tagSource !== undefined) {
  //       if (tagListValue.tagSource.tags !== undefined) {
  //         for (var k = 0; k < tagListValue.tagSource.tags.length; k++) {
  //           tagName = tagListValue.tagSource.tags[k]
  //           searchKey.push({ id: k + 1, name: tagName })
  //         }
  //         setSearchKey(searchKey) //as any
  //       }
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tagListValue])

  // reset table filter value after successfully edit subscription
  useEffect(() => {
    if (editSubscriptionValue?.succssMessage !== '') {
      if (filterModel?.quickFilterValues !== undefined) {
        if (filterModel?.quickFilterValues!.length > 0) {
          setFilterModel({
            items: [],
            quickFilterValues: [],
          })
          searchParams.delete('quick-filter')
          setSearchParams(searchParams)
          dispatch(editSubscriptionClearRes())
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSubscriptionValue])

  function DetailPanelContent({ row }: { row: any }) {
    return <EditSubscription item={row} />
  }

  const getDetailPanelContent = React.useCallback(
    ({ row }: GridRowParams) => <DetailPanelContent row={row} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const getDetailPanelHeight = React.useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelHeight']>
  >(() => 'auto' as const, [])

  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [],
    quickFilterValues: [],
  })

  const handleUpdateSetSearchName = (name: string) => {
    if (name !== undefined) {
      if (name !== '') {
        searchParams.set('searchbyname', name)
        setSearchParams(searchParams)
      }
    }
  }
  const handleUpdateSearchOwner = (owner: string) => {
    if (owner !== undefined) {
      if (owner !== '') {
        searchParams.set('searchbyowner', owner)
        setSearchParams(searchParams)
      }
    }
  }
  const handleUpdateSearchDept = (department: string) => {
    if (department !== undefined) {
      if (department !== '') {
        searchParams.set('searchbydepartment', department)
        setSearchParams(searchParams)
      }
    }
  }

  useEffect(() => {
    if (filterModel.items.length > 0) {
      // Set Name, Owner, Department & QuickFilter value in query param when user use column filter & quick filter actions
      if (filterModel.items.find((e) => e.field === 'name')) {
        if (
          filterModel.items.find((e) => e.field === 'name')?.value !== undefined ||
          filterModel.items.find((e) => e.field === 'name')?.value !== ''
        ) {
          handleUpdateSetSearchName(filterModel.items.find((e) => e.field === 'name')?.value)
        } else {
          navigate('.', { replace: true, state: { key: Date.now() } })
        }
      }
      if (filterModel.items.find((e) => e.field === 'userEmail')) {
        if (
          filterModel.items.find((e) => e.field === 'userEmail')?.value !== undefined ||
          filterModel.items.find((e) => e.field === 'userEmail')?.value !== ''
        ) {
          handleUpdateSearchOwner(filterModel.items.find((e) => e.field === 'userEmail')?.value!)
        } else {
          navigate('.', { replace: true, state: { key: Date.now() } })
        }
      }
      if (filterModel.items.find((e) => e.field === 'department')) {
        if (
          filterModel.items.find((e) => e.field === 'department')?.value !== undefined ||
          filterModel.items.find((e) => e.field === 'department')?.value !== ''
        ) {
          handleUpdateSearchDept(filterModel.items.find((e) => e.field === 'department')?.value!)
        } else {
          navigate('.', { replace: true, state: { key: Date.now() } })
        }
      }

      if (
        searchParams.get('searchbyname')! !== null &&
        filterModel.items.find((e) => e.field === 'name') === undefined
      ) {
        searchParams.delete('searchbyname')
        setSearchParams(searchParams)
      } else if (
        searchParams.get('searchbyowner')! !== null &&
        (filterModel.items.find((e) => e.field === 'userEmail') === undefined ||
          filterModel.items.find((e) => e.field === 'userEmail')?.value === '')
      ) {
        searchParams.delete('searchbyowner')
        setSearchParams(searchParams)
      } else if (
        searchParams.get('searchbydepartment')! !== null &&
        (filterModel.items.find((e) => e.field === 'department') === undefined ||
          filterModel.items.find((e) => e.field === 'department')?.value === '')
      ) {
        searchParams.delete('searchbydepartment')
        setSearchParams(searchParams)
      }
      if (filterModel?.quickFilterValues !== undefined) {
        if (filterModel?.quickFilterValues!.length > 0) {
          searchParams.set('quick-filter', filterModel?.quickFilterValues![0])
          setSearchParams(searchParams)
        } else {
          searchParams.delete('quick-filter')
          setSearchParams(searchParams)
        }
      } else {
        searchParams.delete('quick-filter')
        setSearchParams(searchParams)
      }
    } else {
      searchParams.delete('searchbyname')
      searchParams.delete('searchbyowner')
      searchParams.delete('searchbydepartment')
      setSearchParams(searchParams)
      // Set QuickFilter value in query param when user does not use column filter
      if (filterModel?.quickFilterValues !== undefined) {
        if (filterModel?.quickFilterValues.length > 0) {
          searchParams.set('quick-filter', filterModel?.quickFilterValues[0])
          setSearchParams(searchParams)
        } else {
          searchParams.delete('quick-filter')
          setSearchParams(searchParams)
          navigate('.', { replace: true, state: { key: Date.now() } })
        }
      } else {
        searchParams.delete('quick-filter')
        setSearchParams(searchParams)
        navigate('.', { replace: true, state: { key: Date.now() } })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterModel])

  // table checkbox selection
  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([])
  // find selected subscription details on table selection & save that data in store for add department into that selected items
  useEffect(() => {
    if (selectionModel !== undefined) {
      let selectedSubscriptions = [] as any[]
      selectionModel.forEach((item: any) => {
        if (!selectedSubscriptions.includes(item)) {
          selectedSubscriptions.push(subscriptionList.find((o) => o.id === item))
        }
      })
      dispatch(updateSubscriptionList(selectedSubscriptions))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionModel, subscriptionList])

  // clear subscription table selected value when subscription table value gets changed
  useEffect(() => {
    if (ignoredSubsValue?.ignoredsubcriptions?.results !== undefined) {
      setSelectionModel([])
    }
  }, [ignoredSubsValue])

  useEffect(() => {
    if (editSubscriptionValue?.succssMessage !== '') {
      dispatch(ignoredSubscription(Token!))
      dispatch(editSubscriptionClearRes())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSubscriptionValue])

  // Table Column visibility
  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      alert: true,
      name: true,
      account: false,
      renewalDate: true,
      userEmail: true,
      department: true,
      yearlyBudget: true,
      quarterlyBudget: true,
      monthlyBudget: true,
      status: true,
    })

  return (
    <Box>
      <Card>
        <CardHeader
          avatar={<CloudOffIcon sx={{ fontWeight: 'bold' }} />}
          title={
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  Ignored Subscriptions
                </Typography>
              </Grid>
              <Grid item>
                <Chip label={subscriptionList.length ? String(subscriptionList.length) : '0'} />
              </Grid>
              <Grid item>
                <IconButton onClick={() => dispatch(ignoredSubscription(Token!))}>
                  <RefreshIcon />
                </IconButton>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                <Autocomplete
                  id="combo-box-demo"
                  options={searchKey}
                  value={selectedTag}
                  onChange={(event: any, newValue: any) => {
                    filterSearch(newValue, event)
                  }}
                  getOptionLabel={(option: any) => option.name}
                  isOptionEqualToValue={(option: any, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      placeholder="Select Tag Name"
                      variant="standard"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocalOfferIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  sx={{ minWidth: fullScreen ? '128px' : '200px' }}
                />
              </Grid> */}
              <Grid item>
                <Box id="filter-panel" />
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {ignoredSubsValue.loading || searchTagListValue.loading ? (
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <CircularProgress color="inherit" size={20} thickness={4} />
            </Grid>
          ) : (
            <Box sx={{ height: 'calc(100vh - 265px)' }}>
              <DataGridPremium
                columns={SubscriptionTableColumn()}
                rows={subscriptionList}
                getDetailPanelHeight={getDetailPanelHeight}
                getDetailPanelContent={getDetailPanelContent}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'name', sort: 'asc' }],
                  },
                }}
                filterModel={filterModel}
                onFilterModelChange={(model) => setFilterModel(model)}
                slots={{
                  toolbar: ApplogieDGToolbar,
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                hideFooter
                checkboxSelection
                onRowSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel)
                }}
                rowSelectionModel={selectionModel}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                disableRowGrouping
                disableAggregation
                disableRowSelectionOnClick
                loading={individualSubscriptionAlertRes.loading || ignoredSubsValue.loading}
                localeText={{ noRowsLabel: 'No Subscriptions are available' }}
              />
            </Box>
          )}
        </CardContent>
      </Card>

      {showAlertDialog ? (
        <AlertPopup
          alertDialogEnable={showAlertDialog}
          alertDialogUpdateState={updateAlertDialogValFromChild}
        />
      ) : null}
    </Box>
  )
}
