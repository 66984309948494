import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material'
import * as React from 'react'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import dayjs, { Dayjs } from 'dayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { updateSubscription } from 'reduxStore/subcription/updateSubscription/updateSubscriptionSlice'
import {
  RawBody,
  updateSubscriptionBody,
} from 'reduxStore/subcription/updateSubscription/updateSubscriptionModel'
import CancelIcon from '@mui/icons-material/Cancel'
import { Result as CompanyUser } from 'reduxStore/customer/companyUser/companyUserList/Model'
import { AccountDetailsModelReq } from 'reduxStore/account/accountDetailsById/accountDetailsModel'
import { fetchAccountDetailsById } from 'reduxStore/account/accountDetailsById/accountDetailsSlice'
import { useParams } from 'react-router-dom'
import MicrosoftIntDialog from 'pages/MicrosoftUser/MicrosoftIntDialog'
import { numberWithCommas } from 'utils/numberWithCommas'
import AccDetailsOptimizations from 'pages/Company/AccountDetails/Optimizations/AccDetailsOptimizations'
import { styled } from '@mui/material/styles'
import { checkReturnValue, renderApplogieDate } from 'components/Utility'
interface OwnerList {
  label: string
  id: number
}
const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(21, 171, 209, 0.87)',
    color: 'rgba(255, 255, 255, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))
export default function AccountDetails() {
  // Get the userId param from the URL.

  const [isEditing, setIsEditing] = React.useState(false);
  const [note, setNote] = React.useState("");

  // Correctly typing the ref
  const textFieldRef = React.useRef<HTMLDivElement | null>(null);

  const handleSave = () => {
    setIsEditing(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      textFieldRef.current &&
      !textFieldRef.current.contains(event.target as Node)
    ) {
      handleSave();
    }
  };

  React.useEffect(() => {
    if (isEditing) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, [isEditing]);
  let { id } = useParams()
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const accountDetailsByid = useAppSelector((state) => state.accountDetails)
  const comapnyAccountDetailsById = useAppSelector((state) => state.companyAccountDetailsSlice)
  const subsDeptRes = useAppSelector((state) => state.subsdepartment)
  const updateSubscriptionValue = useAppSelector((state) => state.updateSubscription)
  const optimizationsDetailsbyAccountID = useAppSelector(
    (state) => state.optimizationsDetailsbyAccountID,
  )

  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const [opencollapse, setCollapse] = React.useState(false)
  const [microsoftIntCheck, setMicrosoftIntCheck] = React.useState(false)
  const [microsoftIntDialog, setMicrosoftIntDialog] = React.useState(false)
  const msIntegrationDialogOpen = () => {
    if (microsoftIntCheck === true) {
      setMicrosoftIntDialog(true)
      setCollapse(false)
    } else {
      setMicrosoftIntDialog(false)
      setCollapse(true)
    }
  }

  const updateDeletePaymentDialogValFromChild = (passedVal: boolean) => {
    setMicrosoftIntDialog(passedVal)
  }
  const [access_key, setAccessKey] = React.useState('')
  const [access_text, setAccessText] = React.useState('')
  const [api_key, setApiKey] = React.useState('')
  const [aliasName, setAliasName] = React.useState('')
  const [vendorName, setVendorName] = React.useState('')
  const [ac_number, setAccountNumber] = React.useState('')
  const [notes_value, setNotesValue] = React.useState('')
  const [departmentList, setDepartmentList] = React.useState([] as any[])
  const [selectedDepartment, setSelectedDepartment] = React.useState<any | null>(null)
  const [renewalDate, setRenewalDate] = React.useState<Dayjs | null>(null)
  const changeRenewalDate = (newValue: Dayjs | null) => {
    setRenewalDate(dayjs(newValue))
  }
  const [monthly_cost, setMonthlyCost] = React.useState('')

  const [ownerList, setOwnerList] = React.useState([] as any[])
  const [selectedOwner, setSelectedOwner] = React.useState<OwnerList | null>(null)
  function handleLimitChange(value: OwnerList | null) {
    setSelectedOwner(value)
  }

  const [email, setEmail] = React.useState('')
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastname] = React.useState('')
  const [showDateErrorMsg, setShowDateErrorMsg] = React.useState(false)
  const [dateErrorMsg, setDateErrorMsg] = React.useState('')
  const [maxWidth] = React.useState<DialogProps['maxWidth']>(false as any)

  const openWebAddress = (webAdress: string) => {
    webAdress !== 'Not Set' && webAdress.includes('https://')
      ? window!.open!(webAdress)
      : window!.open!(`https://${webAdress}`)
  }
  const sendMail = (email: string) => {
    window.location.href =
      // eslint-disable-next-line no-useless-concat
      'mailto:' + email + '?cc=' + '' + '&subject=' + vendorName + ' Question' + '&body=' + ''
  }
  const changeDepartment = (department: any) => {
    setSelectedDepartment(department)
  }

  const [showInactivePaymentDialog, setShowInActivePaymentDialog] = React.useState(false)

  const updateSubscriptionApiCall = async () => {
    if (renewalDate !== null) {
      setShowDateErrorMsg(false)
      setDateErrorMsg('')
      let updatedbody = {
        access_key: access_key,
        access_text: access_text,
        api_key: api_key,
        account_number: ac_number,
        department_id: selectedDepartment?.value,
        description: notes_value,
        monthly_projected_cost: monthly_cost,
        owner_id: selectedOwner?.id,
        renewal_date: dayjs(renewalDate).format('YYYY-MM-DD') + 'T12:00',
      } as RawBody

      if (aliasName !== null || aliasName !== '') {
        Object.assign(updatedbody, { vendor_alias: aliasName })
      }

      let mainbody = {
        id: String(accountDetailsByid?.accountDetails?.id!),
        updateBody: updatedbody,
        token: Token!,
      } as updateSubscriptionBody
      await dispatch(updateSubscription(mainbody))
      let accountDetailsByIdBody = {
        accountId: String(id),
        token: Token,
      } as AccountDetailsModelReq
      await dispatch(fetchAccountDetailsById(accountDetailsByIdBody))
      await setOpen(false)
    } else {
      setDateErrorMsg('Date is required')
      setShowDateErrorMsg(true)
    }
  }

  React.useEffect(() => {
    setNotesValue(note);
  }, [note]);

  const integrationApiCall = () => {
    let updatedbody = {
      access_key: access_key,
      access_text: access_text,
      api_key: api_key,
      account_number: ac_number,
      department_id: selectedDepartment?.value,
      description: notes_value,
      monthly_projected_cost: monthly_cost,
      renewal_date: dayjs(renewalDate).format('YYYY-MM-DD') + 'T12:00',
    } as RawBody

    let mainbody = {
      id: String(accountDetailsByid?.accountDetails?.id!),
      updateBody: updatedbody,
      token: Token!,
    } as updateSubscriptionBody

    dispatch(updateSubscription(mainbody))
  }

  React.useEffect(() => {
    setAccessKey(accountDetailsByid.accountDetails.access_key)
    setAccessText(accountDetailsByid.accountDetails.access_text)
    setApiKey(accountDetailsByid.accountDetails.api_key)
    setAccountNumber(accountDetailsByid.accountDetails.account_number)
    setNotesValue(accountDetailsByid.accountDetails.description)
    setVendorName(accountDetailsByid.accountDetails?.vendor?.name!)
    setMonthlyCost(accountDetailsByid.accountDetails.monthly_projected_cost)
    setRenewalDate(
      checkReturnValue(accountDetailsByid?.accountDetails?.renewal_date) !== 'Not Set'
        ? dayjs(checkReturnValue(accountDetailsByid?.accountDetails?.renewal_date))
        : null,
    )
    setAliasName(
      checkReturnValue(accountDetailsByid?.accountDetails?.vendor_alias) !== 'Not Set'
        ? checkReturnValue(accountDetailsByid?.accountDetails?.vendor_alias)
        : '',
    )
    setMicrosoftIntCheck(
      checkReturnValue(accountDetailsByid?.accountDetails?.vendor?.name) !== 'Not Set'
        ? checkReturnValue(accountDetailsByid?.accountDetails?.vendor?.name) === 'Microsoft'
          ? true
          : false
        : false,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountDetailsByid])
  React.useEffect(() => {
    setDepartmentList(
      checkReturnValue(subsDeptRes?.department?.results) !== 'Not Set'
        ? subsDeptRes?.department?.results.map((deptItem: any) => ({
          label: deptItem.name,
          value: deptItem.id,
        }))
        : ([] as any[]),
    )
  }, [subsDeptRes])
  React.useEffect(() => {
    // eslint-disable-next-line no-lone-blocks, @typescript-eslint/no-unused-expressions

    setEmail(checkReturnValue(accountDetailsByid?.accountDetails?.owner?.user?.email))
    setFirstName(checkReturnValue(accountDetailsByid?.accountDetails?.owner?.user?.first_name))
    setLastname(checkReturnValue(accountDetailsByid?.accountDetails?.owner?.user?.last_name))
    setNote(accountDetailsByid?.accountDetails?.description)
    if (
      checkReturnValue(accountDetailsByid?.accountDetails?.owner?.user) !== 'Not Set' &&
      ownerList.length > 0
    ) {
      setSelectedOwner(
        ownerList.find(
          (item) => item.label === accountDetailsByid.accountDetails?.owner?.user?.username!,
        ),
      )
    }
    if (
      checkReturnValue(accountDetailsByid?.accountDetails?.department) !== 'Not Set' &&
      departmentList.length > 0
    ) {
      setSelectedDepartment(
        departmentList.find(
          (item) => item.label === accountDetailsByid?.accountDetails?.department?.name!,
        ),
      )
    }
  }, [accountDetailsByid, departmentList, ownerList])

  const companyUserList = useAppSelector((state) => state.companyUserList)

  React.useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    if (checkReturnValue(companyUserList?.companyUserList?.results) !== 'Not Set') {
      if (companyUserList.companyUserList.results.length > 0) {
        let ownerArr = companyUserList.companyUserList.results.map((item: CompanyUser) => {
          return {
            label: item.user?.username!,
            id: item.id,
          }
        })
        setOwnerList(ownerArr)
      } else setOwnerList([] as any[])
    } else setOwnerList([] as any[])
  }, [companyUserList])

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              {accountDetailsByid?.accountDetails?.owner?.user?.first_name && accountDetailsByid?.accountDetails?.owner?.user?.last_name ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <CustomTooltip title={accountDetailsByid?.accountDetails?.vendor?.web_address} placement="bottom" arrow>
                      <Typography
                        variant="h6"
                        onClick={() =>
                          openWebAddress(
                            checkReturnValue(accountDetailsByid?.accountDetails?.vendor?.web_address),
                          )
                        }
                        sx={{
                          color: '#30a289',
                          fontWeight: 'bold',
                          '&:hover': { color: '#134872', cursor: 'pointer', fontWeight: 'bold' },
                        }}
                      >
                        {checkReturnValue(accountDetailsByid?.accountDetails?.vendor?.name)}
                      </Typography>
                    </CustomTooltip>
                  </Grid>
                  <Grid item>|</Grid>
                  <Grid item>
                    <Typography
                      variant="body1"
                      onClick={() => sendMail(email !== '' ? email : 'not set')}
                      sx={{
                        color: '#30a289',
                        fontWeight: 'bold',
                        paddingTop: '4px',
                        '&:hover': { color: '#134872', cursor: 'pointer', fontWeight: 'bold' },
                      }}
                    >
                      {firstName !== '' && lastName !== '' ? firstName + ' ' + lastName : 'Not Set'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {optimizationsDetailsbyAccountID?.optimizations?.count! !== null ? (
                      optimizationsDetailsbyAccountID?.optimizations!.count! > 0 ? (
                        <Box>
                          <CustomTooltip
                            disableFocusListener
                            title="The number of potential optimizations for this subscriptions"
                            arrow
                          >
                            <Chip
                              label={
                                optimizationsDetailsbyAccountID?.optimizations?.count! !== null ? (
                                  optimizationsDetailsbyAccountID?.optimizations!.count! > 0 ? (
                                    <Typography sx={{ color: '#ffffff' }}>
                                      {optimizationsDetailsbyAccountID?.optimizations!.count!}
                                    </Typography>
                                  ) : null
                                ) : null
                              }
                              sx={{ backgroundColor: '#d32f2f' }}
                              onClick={() => setShowInActivePaymentDialog(true)}
                            />
                          </CustomTooltip>
                        </Box>
                      ) : null
                    ) : null}
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <CustomTooltip title={accountDetailsByid?.accountDetails?.vendor?.web_address} placement="bottom" arrow>
                      <Typography
                        variant="h6"
                        onClick={() =>
                          openWebAddress(
                            checkReturnValue(accountDetailsByid?.accountDetails?.vendor?.web_address),
                          )
                        }
                        sx={{
                          color: '#30a289',
                          fontWeight: 'bold',
                          '&:hover': { color: '#134872', cursor: 'pointer', fontWeight: 'bold' },
                        }}
                      >
                        {checkReturnValue(accountDetailsByid?.accountDetails?.vendor?.name)}
                      </Typography>
                    </CustomTooltip>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item>
              <IconButton onClick={handleClickOpen}>
                <BorderColorIcon />
              </IconButton>
            </Grid>
          </Grid>
        }
      ></CardHeader>
      <CardContent>
        {accountDetailsByid.loading && comapnyAccountDetailsById.loading ? (
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item>
              <CircularProgress color="inherit" size={30} />
            </Grid>
          </Grid>
        ) : (
          <Box sx={{ width: '100%' }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              pr={3}
            >
              <Grid item>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Grid item>
                    <Typography variant="body2">Alias Name</Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#134873' }}>
                      {accountDetailsByid?.accountDetails?.vendor_alias !== ''
                        ? accountDetailsByid?.accountDetails?.vendor_alias!
                        : 'Not Set'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">Category</Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#134873' }}>
                      {checkReturnValue(
                        accountDetailsByid?.accountDetails?.vendor?.vendor_class?.class_name,
                      )}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">Department</Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#134873' }}>
                      {checkReturnValue(accountDetailsByid?.accountDetails?.department?.name)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">Account Number</Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#134873' }}>
                      {checkReturnValue(accountDetailsByid?.accountDetails?.account_number)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">Renewal Date</Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#134873' }}>
                      {checkReturnValue(
                        renderApplogieDate(accountDetailsByid?.accountDetails?.renewal_date!),
                      )}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">Spend (Current Year)</Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#134873' }}>
                      {'companyAccountDetails' in comapnyAccountDetailsById
                        ? comapnyAccountDetailsById.companyAccountDetails.length > 0
                          ? 'cost' in comapnyAccountDetailsById.companyAccountDetails[0]
                            ? numberWithCommas(
                              Math.floor(
                                Number(comapnyAccountDetailsById.companyAccountDetails[0].cost),
                              ),
                            )
                            : 'Not Set'
                          : 'Not Set'
                        : 'Not Set'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">Spend (Monthly Average)</Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#134873' }}>
                      {'companyAccountDetails' in comapnyAccountDetailsById
                        ? comapnyAccountDetailsById.companyAccountDetails.length > 0
                          ? 'monthly_avg_spend' in
                            comapnyAccountDetailsById.companyAccountDetails[0]
                            ? numberWithCommas(
                              Math.floor(
                                Number(
                                  comapnyAccountDetailsById.companyAccountDetails[0]
                                    .monthly_avg_spend,
                                ),
                              ),
                            )
                            : 'Not Set'
                          : 'Not Set'
                        : 'Not Set'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    width: 300,
                    backgroundColor: "#e8f4fc",
                    padding: 2,
                    borderRadius: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {/* Header Section */}
                  <Typography
                    variant="subtitle1"
                    sx={{
                      textAlign: "center",
                      marginBottom: 2,
                    }}
                  >
                    Notes
                  </Typography>

                  {/* Note Section */}
                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      // alignItems: isEditing ? "flex-start" : "center",
                      justifyContent: "center",
                      minWidth: '12vw', minHeight: '34vh',
                    }}
                  >
                    {isEditing ? (
                      <TextField
                        inputRef={(element) => (textFieldRef.current = element?.parentElement || null)}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        fullWidth
                        multiline
                      />
                    ) : (
                      <Typography
                        onClick={() => setIsEditing(true)}
                        sx={{
                          cursor: "pointer",
                          textAlign: "center",
                          width: "100%",
                          wordWrap: "break-word",
                          whiteSpace: "pre-wrap",
                          overflowWrap: "break-word",
                          fontWeight: 'bold', color: '#091231', fontSize: '10px'
                        }}
                      >
                        {note || "No note added yet"}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </CardContent>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle id="alert-dialog-title">
          <b>Update Subscription:</b>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                id="subscriptionVendorName-id"
                label="Vendor Name"
                variant="standard"
                fullWidth
                value={vendorName}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                id="subscriptionAliasName-id"
                label="Alias Name"
                variant="standard"
                fullWidth
                value={aliasName}
                onChange={(e) => {
                  setAliasName(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                id="standard-basic"
                label="Account Number"
                variant="standard"
                value={ac_number}
                onChange={(e) => {
                  setAccountNumber(e.target.value)
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Autocomplete
                id="dept-states"
                value={selectedDepartment}
                options={departmentList}
                onChange={(_event: any, newValue: any) => {
                  changeDepartment(newValue)
                }}
                getOptionLabel={(option: any) => option.label}
                isOptionEqualToValue={(option: any, value) => option.value === value.value}
                renderInput={(params) => (
                  <TextField {...params} label="Department Name" variant="standard" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Autocomplete
                value={selectedOwner}
                options={ownerList}
                onChange={(_event, value: any) => handleLimitChange(value!)}
                getOptionLabel={(option) => `${option.label}`}
                isOptionEqualToValue={(option: any, value) => option.id === value.id}
                id="disable-close-on-select"
                renderInput={(params) => (
                  <TextField {...params} label="Owner" variant="standard" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Renewal Date"
                  value={renewalDate}
                  onChange={(newValue) => {
                    changeRenewalDate(newValue)
                  }}
                  slotProps={{ textField: { variant: 'standard' } }}
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
              {showDateErrorMsg ? (
                <Typography variant="body1" sx={{ color: '#f44336' }}>
                  {dateErrorMsg}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                id="standard-basic"
                label="Notes"
                variant="standard"
                value={notes_value}
                onChange={(e) => {
                  setNotesValue(e.target.value)
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => msIntegrationDialogOpen()}
                sx={{ textTransform: 'none' }}
              >
                <Typography sx={{ color: '#000', fontSize: '14px' }}>Integration</Typography>
              </Button>
            </Grid>
          </Grid>
          <Collapse in={opencollapse} timeout="auto" unmountOnExit>
            <Paper sx={{ bgcolor: '#f5f5f5', my: 2 }} variant="outlined">
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={2}
                p={1}
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Stack direction={'row'}>
                      <Typography variant="h6">Update Integration:</Typography>
                    </Stack>
                    <Stack direction={'row'}>
                      <IconButton aria-label="delete" onClick={() => setCollapse(!opencollapse)}>
                        <CancelIcon />
                      </IconButton>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        id="api-key"
                        label="API Key"
                        variant="standard"
                        fullWidth
                        value={api_key}
                        onChange={(e) => {
                          setApiKey(e.target.value)
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        id="access-key"
                        label="Access Key"
                        variant="standard"
                        fullWidth
                        value={access_key}
                        onChange={(e) => {
                          setAccessKey(e.target.value)
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        id="access-text"
                        label="Access Text"
                        variant="standard"
                        fullWidth
                        value={access_text}
                        onChange={(e) => {
                          setAccessText(e.target.value)
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Button onClick={integrationApiCall} autoFocus variant="contained">
                        {updateSubscriptionValue.loading === true ? (
                          <Stack direction="row" spacing={1}>
                            <Typography sx={{ color: '#000', fontSize: '15px' }}>Submit</Typography>
                            <Box pl={1}>
                              <CircularProgress color="inherit" size={10} />
                            </Box>
                          </Stack>
                        ) : (
                          <Typography sx={{ color: '#000', fontSize: '15px' }}>Submit</Typography>
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Collapse>
        </DialogContent>
        <DialogActions sx={{ margin: 3 }}>
          <Button
            onClick={handleClose}
            variant="contained"
            style={{ backgroundColor: '#dcdcdc' }}
            disabled={updateSubscriptionValue.loading}
          >
            <Typography sx={{ color: '#000', fontSize: '15px' }}>Cancel</Typography>
          </Button>
          <Button
            onClick={updateSubscriptionApiCall}
            autoFocus
            variant="contained"
            disabled={updateSubscriptionValue.loading}
          >
            {updateSubscriptionValue.loading === true ? (
              <Stack direction="row" spacing={1}>
                <Typography>Save</Typography>
                <Box pl={1}>
                  <CircularProgress color="inherit" size={10} />
                </Box>
              </Stack>
            ) : (
              <Typography>Save</Typography>
            )}
          </Button>
        </DialogActions>
        <MicrosoftIntDialog
          microsoftIntDialogEnable={microsoftIntDialog}
          microsoftIntDialogUpdateState={updateDeletePaymentDialogValFromChild}
        />
      </Dialog>
      <Dialog
        open={showInactivePaymentDialog}
        maxWidth={maxWidth}
        onClose={() => setShowInActivePaymentDialog(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <AccDetailsOptimizations />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setShowInActivePaymentDialog(false)}
            style={{ backgroundColor: '#dcdcdc', color: '#000' }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}
