import {
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import React, { useEffect } from 'react'
import { useAppSelector } from 'reduxStore/hooks'
type Props = {
    enableDialog: boolean
    updateDialogState: (value: boolean) => void
}

export default function ViewPricingDetails({ enableDialog, updateDialogState }: Props) {
    const accountDetailsByid = useAppSelector((state) => state.accountDetails)
    const [open, setOpen] = React.useState(false)
    const handleClose = () => {
        setOpen(false)
        updateDialogState(false)
    }
    useEffect(() => {
        enableDialog !== undefined ? enableDialog && setOpen(enableDialog) : setOpen(false)
    }, [enableDialog])
    return (
        <Dialog
            onClose={handleClose}
            open={open}
            sx={{
                '& .MuiDialog-container': {
                    '& .MuiPaper-root': {
                        width: '100%',
                        maxWidth: '700px',
                    },
                },
            }}
        >
            <DialogTitle>
                <Grid id="top-row" container spacing={24}>
                    <Grid item xs={9}>
                        <strong>View Pricing Details</strong>
                    </Grid>
                    <Grid item xs={3}>
                        <IconButton aria-label="delete" onClick={handleClose}>
                            <CancelIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="stretch"
                    spacing={1}
                >
                    {accountDetailsByid.loading ? (
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Grid item>
                                <CircularProgress color="inherit" size={30} />
                            </Grid>
                        </Grid>
                    ) : 'accountDetails' in accountDetailsByid ? (
                        accountDetailsByid.accountDetails !== undefined ? (
                            accountDetailsByid.accountDetails !== null ? (
                                'vendor' in accountDetailsByid.accountDetails ? (
                                    accountDetailsByid.accountDetails.vendor !== undefined ? (
                                        accountDetailsByid.accountDetails.vendor !== null ? (
                                            'pricing_details' in accountDetailsByid.accountDetails.vendor ? (
                                                accountDetailsByid.accountDetails.vendor.pricing_details !== undefined ? (
                                                    accountDetailsByid.accountDetails.vendor.pricing_details !== null ? (
                                                        <Box
                                                            mt={1}
                                                            dangerouslySetInnerHTML={{
                                                                __html: accountDetailsByid.accountDetails.vendor.pricing_details,
                                                            }}
                                                        />
                                                    ) : (
                                                        <Typography variant="subtitle1">No content available</Typography>
                                                    )
                                                ) : (
                                                    <Typography variant="subtitle1">No content available</Typography>
                                                )
                                            ) : (
                                                <Typography variant="subtitle1">No content available</Typography>
                                            )
                                        ) : (
                                            <Typography variant="subtitle1">No content available</Typography>
                                        )
                                    ) : (
                                        <Typography variant="subtitle1">No content available</Typography>
                                    )
                                ) : (
                                    <Typography variant="subtitle1">No content available</Typography>
                                )
                            ) : (
                                <Typography variant="subtitle1">No content available</Typography>
                            )
                        ) : (
                            <Typography variant="subtitle1">No content available</Typography>
                        )
                    ) : (
                        <Typography variant="subtitle1">No content available</Typography>
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
