import { LinearProgressProps, LinearProgressPropsColorOverrides } from '@mui/material'
import { OverridableStringUnion } from '@mui/types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export function budgetProgress(spend: number, budget: number) {
  let progressValue: number
  if (spend > budget) {
    progressValue = Math.ceil((1 - (spend - budget) / spend) * 100)
  } else {
    progressValue = spend !== 0 && budget !== 0 ? Math.ceil((spend * 100) / budget) : 0
  }
  return progressValue
}
export function isBarNormalBackground(spend: number, budget: number) {
  if (spend > budget) {
    return true
  }
  return false
}
export function getBarBackgroundColor(colorSchema: LinearProgressProps['color']) {
  switch (colorSchema) {
    case 'error':
      return '#6d0202'
    case 'warning':
      return '#ad4d03'
    case 'inherit':
      return '#EBEBEB'
    default:
      return '#00a289'
  }
}
export function budgetProgressColor(
  spend: number,
  budget: number,
): OverridableStringUnion<
  'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit',
  LinearProgressPropsColorOverrides
> {
  return Math.ceil(Number((spend! * 100) / budget!)) === Infinity
    ? 'inherit'
    : Math.ceil(Number((spend! * 100) / budget!)) < 100
      ? 'success'
      : Math.ceil(Number((spend! * 100) / budget!)) === 100
        ? 'primary'
        : Math.ceil(Number((spend! * 100) / budget!)) <= 120
          ? 'warning'
          : Math.ceil(Number((spend! * 100) / budget!)) >= 120
            ? 'error'
            : 'info'
}

export function formatCash(n: number) {
  if (n < 1e3) return n
  if (n >= 1e3 && n < 1e5) return +(n / 1e3).toFixed(2) + 'K'
  if (n >= 1e5 && n < 1e9) return +(n / 1e6).toFixed(2) + 'M'
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(2) + 'B'
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T'
}

//   // read the local storage
//   // convert it to an array
//   // check if "sectionName" is available in the array
export function isSectionNameAvailable(sectionName: string): boolean {
  let sectionState = localStorage.getItem('sectionName')
  // Check if sectionState is null or undefined
  if (sectionState === null || sectionState === undefined) {
    return false
  }
  let sectionStateObj = sectionState.split(',')
  return sectionStateObj.includes(sectionName)
}

export function isSubsSectionNameAvailable(subsSectionName: string): boolean {
  let subsSectionState = localStorage.getItem('subsSectionName')
  if (subsSectionState === null || subsSectionState === undefined) {
    return false
  }
  let subsSectionStateObj = subsSectionState.split(',')
  return subsSectionStateObj.includes(subsSectionName)
}

export function checkReturnValue(value: any) {
  if (value == null) {
    // The value is either undefined or null
    // Handle the case accordingly
    return 'Not Set'
  } else if (value === '') {
    // The value is either empty
    // Handle the case accordingly
    return 'Not Set'
  } else {
    // The value is not undefined or null
    // Proceed with other logic
    return value
  }
}
export function renderApplogieDate(date: string | number | Date) {
  if (!date) {
    return ''
  }
  if (date === 'Not Set') {
    return 'Not Set'
  }
  return dayjs(date).format('MMM DD, YYYY')
}

export function renderApplogieDateTimeFormat(date: string | number | Date) {
  if (!date) {
    return ''
  }
  return dayjs(date).format('MMMM DD, YYYY HH:mm:ss')
}

export function renderApplogieDateUSFormat(date: Date) {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  if (!date) {
    return ''
  }
  return dayjs(date)
    .utc()
    .tz("America/Los_Angeles", true)
    .startOf('day')
    .format("MMM DD, YYYY")
}