import { Box } from '@mui/material'
import * as React from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5percent from '@amcharts/amcharts5/percent'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { useAppSelector } from 'reduxStore/hooks'
import { Result } from 'reduxStore/vendorSpendLicesnseRenewal/vendorSpendDetails/vendorSpendDetailsModel'
import { numberWithCommas} from 'utils/numberWithCommas'
am5.addLicense(process.env.REACT_APP_AMCHARTS_LICENSE_KEY!)
interface FilteredDeptDetails {
  value: number | undefined
  category: string | undefined
}

export default function VendorSpendCatRenewalDetails() {
  const vendorSpendsDetail = useAppSelector((state) => state.vendorSpendDetails)

  let modifiedArray = [] as FilteredDeptDetails[]

  React.useEffect(() => {
    if (
      'vendorSpendsDetails' in vendorSpendsDetail &&
      vendorSpendsDetail.vendorSpendsDetails.length > 0
    ) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      modifiedArray = vendorSpendsDetail.vendorSpendsDetails.map((item: Result) => {
        return {
          value: Number(item.cost),
          category: item.vendor_name + ' : ' + numberWithCommas(Number(item.cost)),
        }
      })
    }

    /* Chart code */
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    let root = am5.Root.new('vendorSpendRenewalChartDiv')
    var modal = am5.Modal.new(root, {
      content: 'The Vendor Spend category  Renewal chart has no data',
    })
    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)])

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        endAngle: 300,
      }),
    )

    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: 'value',
        categoryField: 'category',
        endAngle: 300,
      }),
    )

    // Set data
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
    series.data.setAll(modifiedArray)

    // Create legend
    // https://www.amcharts.com/docs/v5/charts/percent-charts/legend-percent-series/
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        marginTop: 15,
        marginBottom: 15,
      }),
    )

    legend.data.setAll(series.dataItems)
    if (modifiedArray.length === 0) {
      modal.open()
    } else {
      modal.close()
    }
    // Play initial series animation
    // https://www.amcharts.com/docs/v5/concepts/animations/#Animation_of_series
    series.appear(1000, 500)
    return () => root.dispose()
  }, [vendorSpendsDetail])
  return (
    <Box>
      <div id="vendorSpendRenewalChartDiv" style={{ width: '100%', height: '100vh' }}>
      </div>
    </Box>
  )
}
