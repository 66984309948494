import { Box, IconButton, Link, } from '@mui/material'
import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
} from '@mui/x-data-grid-premium'
import React from 'react'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'
import { renderApplogieDate, renderApplogieDateUSFormat } from 'components/Utility'
import { CustomDetailPanelToggle } from './CustomDetailPanelToggle'
import { useAppDispatch } from 'reduxStore/hooks'
import { fetchOptimizationsDetailsByAccID } from 'reduxStore/optimizations/optimizationsDetailsbyAccId/optimizationSlice'
import { OptimizationsDetailsByAccIdBody } from 'reduxStore/optimizations/optimizationsDetailsbyAccId/optimizationModel'
import { numberWithCommas } from 'utils/numberWithCommas'
import CustomProgressBar from 'components/renderProgress'
export function SubscriptionTableColumn() {
  const columns: GridColDef[] = [
    {
      field: 'alert',
      headerName: 'Alert',
      sortable: true,
      hideable: true,
      width: 60,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const dispatch = useAppDispatch()
        const Token = localStorage.getItem('token')
        let mainBody = {
          id: params.row.accountId,
          token: Token!,
        } as OptimizationsDetailsByAccIdBody
        return (
          <Box>
            {params.row.alert > 0 ? (
              <IconButton
                size="small"
                onClick={() => {
                  dispatch(fetchOptimizationsDetailsByAccID(mainBody))
                }}
              >
                <AccessAlarmIcon fontSize="small" />
              </IconButton>
            ) : null}
          </Box>
        )
      },
    },
    {
      field: 'tableName',
      headerName: 'Name',
      sortable: true,
      hideable: false,
      minWidth: 230,
      headerAlign: 'left',
      renderCell: (params: GridCellParams) => {
        // Use react-router's useNavigate hook to navigate
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return (
          <Link href={`/detail/${params.row.accountId}`} variant="subtitle2" underline="none">
            <b> {params.row.tableName !== null ? params.row.tableName : '---'}</b>
          </Link>
        )
      },
    },
    {
      field: 'account',
      headerName: 'Account',
      sortable: true,
      hideable: true,
      minWidth: 120,
      headerAlign: 'left',
    },
    {
      field: 'modifiedRenewalDate',
      headerName: 'Renewal Date',
      sortable: true,
      hideable: true,
      minWidth: 100,
      headerAlign: 'left',
      renderCell: (params: GridCellParams) => (
        <Box>
          {renderApplogieDate(params.row.modifiedRenewalDate) !== null &&
            renderApplogieDate(params.row.modifiedRenewalDate)}
        </Box>
      ),
    },
    {
      field: 'lastPaymentDate',
      headerName: 'Last Payment Date',
      description: 'Date of last payment processed',
      sortable: true,
      hideable: true,
      minWidth: 160,
      headerAlign: 'left',
      renderCell: (params: GridCellParams) => (
        <Box>
          {renderApplogieDateUSFormat(params.row.lastPaymentDate) !== null &&
            renderApplogieDateUSFormat(params.row.lastPaymentDate)}
        </Box>
      ),
    },
    {
      field: 'userEmail',
      headerName: 'Owner',
      sortable: true,
      hideable: true,
      minWidth: 150,
      headerAlign: 'left',
      renderCell: (params: GridCellParams) => (
        <>
          {params.row.userEmail !== 'Not Set' && params.row.userEmail && (
            <Link
              href={`mailto:${
                params.row.userEmail !== 'Not Set' && params.row.userEmail
              } ?cc= + '' +  &subject= ${params.row.name} Question`}
              variant="subtitle2"
              underline="none"
            >
              <b>
                {params.row.userFirstName !== '' && params.row.userLastName !== ''
                  ? params.row.userFirstName + ' ' + params.row.userLastName
                  : ''}
              </b>
            </Link>
          )}
        </>
      ),
    },
    {
      field: 'department',
      headerName: 'Department',
      sortable: true,
      hideable: true,
      minWidth: 200,
      headerAlign: 'left',
      renderCell: (params: GridCellParams) => (
        <Box>{params.row.department !== 'Not Set' ? params.row.department : null}</Box>
      ),
    },

    {
      field: 'yearlySpend',
      headerName: 'Annual Spend',
      sortable: true,
      hideable: true,
      minWidth: 120,
      headerAlign: 'right',
      renderCell: (params: GridCellParams) => (
        <Box
          sx={{
            lineHeight: 'normal',
            height: '100%',
            display: 'grid',
            textAlign: 'right',
            width: '100%',
            alignItems: 'center',
          }}
        >
          {params.row.yearlySpend !== null && numberWithCommas(params.row.yearlySpend)}
        </Box>
      ),
    },
    {
      field: 'yearlyBudget',
      headerName: 'Annual Budget',
      sortable: true,
      hideable: true,
      minWidth: 120,
      headerAlign: 'right',
      renderCell: (params: GridCellParams) => (
        <Box
          sx={{
            lineHeight: 'normal',
            height: '100%',
            display: 'grid',
            textAlign: 'right',
            width: '100%',
            alignItems: 'center',
          }}
        >
          {params.row.yearlyBudget !== null && numberWithCommas(params.row.yearlyBudget)}
        </Box>
      ),
    },
    {
      field: 'spendBudget',
      headerName: 'Spend / Budget',
      minWidth: 320,
      description: 'Year to Date spend vs Annual Budget',
      headerAlign: 'right',
      renderCell: (params: GridCellParams) => (
        <Box sx={{
          lineHeight: 'normal',
          height: '100%',
          display: 'grid',
          textAlign: 'right',
          width: '100%',
          alignItems: 'center',
        }}>
          <CustomProgressBar value={params.value as number} />
        </Box>
      ),
    },
    {
      field: 'quarterlySpend',
      headerName: 'Quarter Spend',
      sortable: true,
      hideable: true,
      minWidth: 120,
      headerAlign: 'right',
      renderCell: (params: GridCellParams) => (
        <Box
          sx={{
            lineHeight: 'normal',
            height: '100%',
            display: 'grid',
            textAlign: 'right',
            width: '100%',
            alignItems: 'center',
          }}
        >
          {params.row.quarterlySpend !== null && numberWithCommas(params.row.quarterlySpend)}
        </Box>
      ),
    },
    {
      field: 'quarterlyBudget',
      headerName: 'Quarter Budget',
      sortable: true,
      hideable: true,
      minWidth: 120,
      headerAlign: 'right',
      renderCell: (params: GridCellParams) => (
        <Box
          sx={{
            lineHeight: 'normal',
            height: '100%',
            display: 'grid',
            textAlign: 'right',
            width: '100%',
            alignItems: 'center',
          }}
        >
          {params.row.quarterlyBudget !== null && numberWithCommas(params.row.quarterlyBudget)}
        </Box>
      ),
    },
    {
      field: 'monthlySpend',
      headerName: 'Month Spend',
      sortable: true,
      hideable: true,
      minWidth: 120,
      headerAlign: 'right',
      renderCell: (params: GridCellParams) => (
        <Box
          sx={{
            lineHeight: 'normal',
            height: '100%',
            display: 'grid',
            textAlign: 'right',
            width: '100%',
            alignItems: 'center',
          }}
        >
          {params.row.monthlySpend !== null && numberWithCommas(params.row.monthlySpend)}
        </Box>
      ),
    },
    {
      field: 'monthlyBudget',
      headerName: 'Month Budget',
      sortable: true,
      hideable: true,
      minWidth: 120,
      headerAlign: 'right',
      renderCell: (params: GridCellParams) => (
        <Box
          sx={{
            lineHeight: 'normal',
            height: '100%',
            display: 'grid',
            textAlign: 'right',
            width: '100%',
            alignItems: 'center',
          }}
        >
          {params.row.monthlyBudget !== null && numberWithCommas(params.row.monthlyBudget)}
        </Box>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: true,
      hideable: true,
      minWidth: 80,
      align: 'right',
      headerAlign: 'right',
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      align: 'center',
      minWidth: 80,
      headerName: 'Action',
      sortable: false,
      hideable: false,

      renderCell: (params: GridCellParams) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
      renderHeader: (_params: GridColumnHeaderParams) => <span>{'Action'}</span>,
    },
  ]
  return columns
}
