export const DrawerList = [
  {
    name: 'Overview',
    path: '/dashboard',
  },
  {
    name: 'Subscriptions',
    path: '/subscriptions',
  },
  {
    name: 'Users',
    path: '/users',
  },
  {
    name: 'Optimizations',
    path: '/optimizations',
  },
  // {
  //   name: 'Breaches',
  //   path: '/breaches',
  // },
  {
    name: 'Log In',
    path: '/login',
  },
  // {
  //   name: "User Profile",
  //   path: "/admindetails",
  //   icon: <AccountCircleIcon />,
  // },
  // {
  //   name: "Sign out",
  //   path: "/dashboard",
  //   icon: <LogoutIcon />,
  // },
]
